import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import checkBoxStep2 from "../data/step2.json";
import checkBoxStep3 from "../data/step3.json";
import checkBoxStep4 from "../data/extra.json";
import Preventivo from "./generatePdf";
import ReactPDF from '@react-pdf/renderer';
import { Base64 } from 'js-base64';
import PopupForm from './PopupForm';
import { Dialog, Transition } from '@headlessui/react';
import SendLoadPdf from "./sendLoadPdf"
interface Job {
  id: string;
  nome: string;
  tipo_calcolo?: string;
  prezzo: number;
  desc: string;
}

interface Job3 {
  id: string;
  nome: string;
  tipo_calcolo?: string;
  prezzo: number;
  desc: string;
}

interface Job4 {
  id: string;
  nome: string;
  tipo_calcolo?: string;
  prezzo: number;
  desc: string;
}

interface CheckBoxStep2 {
  [key: string]: Job;
}

interface CheckBoxStep3 {
  [key: string]: Job3;
}

interface CheckBoxStep4 {
  [key: string]: Job4;
}

type FinalStepProps = {
  formData: any;
  partialValue: number;
  partialValue2: number;
  handleSubmitStep2: any;
  handleSubmitStep3: any;
  selectedServicesStep4: string[]; // Aggiungi questa riga
  partialValue3: number;
  onSubmit: (checkout: string[]) => void;
};


const Step1: React.FC<{ onNextStep: (formData: any) => void }> = ({ onNextStep }) => {
  const [formData, setFormData] = useState({
    nome: '',
    cognome: '',
    email: '',
    telefono: '',
    metriQuadri: '',
    metriLineari: '',
    numStrutture: ''
  });


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleNextStep = () => {
    // Salvare i dati del form in un cookie o una sessione
    // esempio: document.cookie = JSON.stringify(formData);
    onNextStep(formData);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  // Passa al prossimo step
  onNextStep(formData);

  // Salva i dati del form nel cookie
  Cookies.set('Step1', JSON.stringify(formData));
};


  return (

<div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div className="sm:mx-auto sm:w-full">
    <h1 className="mt-10 mb-10 text-2xl font-bold text-center">Step 1: Dati Personali</h1>
  </div>
  <div className="flex">
    <div className="basis-1/4">

    </div>
    <div className="basis-2/4">
    <form onSubmit={handleFormSubmit} className="space-y-6" action="#" method="POST">
        <div>
          <label htmlFor="nome" className="block text-sm font-medium bg-dark leading-6 text-gray-900">Nome</label>
          <div className="mt-2">
          <input type="text" id="nome" name="nome" value={formData.nome} onChange={handleChange} className="border p-2 rounded-md w-full" />
          </div>
        </div>

        <div>
          <label htmlFor="cognome" className="block text-sm font-medium bg-dark leading-6 text-gray-900">Cognome</label>
          <div className="mt-2">
          <input type="text" id="cognome" name="cognome" value={formData.cognome} onChange={handleChange} className="border p-2 rounded-md w-full" />
          </div>
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium bg-dark leading-6 text-gray-900">Email</label>
          <div className="mt-2">
            <input type="text" id="email" name="email" value={formData.email} onChange={handleChange} className="border p-2 rounded-md w-full" />
          </div>
        </div>

        <div>
          <label htmlFor="telefono" className="block text-sm font-medium bg-dark leading-6 text-gray-900">Telefono</label>
          <div className="mt-2">
            <input type="text" id="telefono" name="telefono" value={formData.telefono} onChange={handleChange} className="border p-2 rounded-md w-full" />
          </div>
        </div>
        <div>
          <label htmlFor="numStrutture" className="block text-sm font-medium bg-dark leading-6 text-gray-900">Numero delle strutture</label>
          <div className="mt-2">
            <input type="number" id="numStrutture" name="numStrutture" value={formData.numStrutture} onChange={handleChange} className="border p-2 rounded-md w-full" />
          </div>
        </div>
        <div>
          <label htmlFor="metriQuadri" className="block text-sm font-medium bg-dark leading-6 text-gray-900">Metri Quadri</label>
          <div className="mt-2">
            <input type="number" id="metriQuadri" name="metriQuadri" value={formData.metriQuadri} onChange={handleChange} className="border p-2 rounded-md w-full" />
          </div>
        </div>
        <div>
          <label htmlFor="metriLineari" className="block text-sm font-medium bg-dark leading-6 text-gray-900">Metri Lineari</label>
          <div className="mt-2">
            <input type="number" id="metriLineari" name="metriLineari" value={formData.metriLineari} onChange={handleChange} className="border p-2 rounded-md w-full" />
          </div>
        </div>
        <div>
          <button type="submit" className="flex w-full bg-black justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blackHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600  sm:max-w-sm">Prosegui</button>
        </div>
      </form>
    </div>
    <div className="basis-1/4">
    </div>
  </div>
</div>
  );
};

const Step2: React.FC<{ onSubmit: (selectedItems: string[]) => void; setPartial: (partialTotal: number) => void;  formData: any }> = ({ onSubmit, setPartial, formData }) => {

  // Definisci lo stato per gli elementi selezionati
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  //totale dello step 2
  const [partialTotal, setPartialTotal] = useState<number>(0);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target;
      if (checked) {
        setSelectedItems([...selectedItems, value]);
      } else {
        setSelectedItems(selectedItems.filter(item => item !== value));
      }
    };


    const calculateTotal = (): number => {
      let total = 0;

      if (selectedItems && formData) {
        selectedItems.forEach(item => {
          const job = checkBoxStep2.find(job => job.nome === item);
          if (job) {
            let price = job.prezzo;

            switch (job.tipo_calcolo) {
              case "ML":
                price *= parseFloat(formData.metriLineari);
                break;
              case "MQ":
              if (job.id == 5) { // ID 5
                price = (formData.metriQuadri * 0.2) * job.prezzo;
              } else if (job.id == 9) {
                price = (formData.metriQuadri * 3.5) * job.prezzo;
              } else if (job.id == 10) {
                price = (formData.metriQuadri * 1.7) * job.prezzo;
              } else if (job.id == 1) { //moltiplica il prezzo della fornitura  per il numero di case
                price = (parseFloat(formData.metriQuadri)  * job.prezzo) * formData.numStrutture;
              } else {
                price = job.prezzo * parseFloat(formData.metriQuadri);
              }
                break;
              case "NR":
                price *= parseFloat(formData.numStrutture);
                break;
              default:
                break;
            }

            total += price;
          }
        });
      }
      return total;
    };


  // Funzione per gestire l'invio del form
  const handleSubmitStep2 = () => {
    // Salva i dati selezionati nel cookie
    Cookies.set('Step2', JSON.stringify(selectedItems));
    const total = calculateTotal();
    setPartial(total);
    onSubmit(selectedItems);
  };

  const calculateSinglePrice = (job: Job): number => {
    let price = 0;
    const mqValue = parseFloat(formData.metriQuadri);
    switch (job.tipo_calcolo) {
      case 'ML':
        price = job.prezzo * parseFloat(formData.metriLineari);
        break;
      case 'MQ':
        if (parseInt(job.id) == 5) { // ID 5
          price = (mqValue * 0.2) * job.prezzo;
        } else if (parseInt(job.id) == 9) {
          price = (mqValue * 3.5) * job.prezzo;
        } else if (parseInt(job.id) == 10) {
          price = (mqValue * 1.7) * job.prezzo;
        } else if (parseInt(job.id) == 1) {
          price = (mqValue * job.prezzo) * parseFloat(formData.numStrutture);
        } else {
          price = job.prezzo * parseFloat(formData.metriQuadri);
        }
        break;
      case 'NR':
        price = job.prezzo * parseFloat(formData.numStrutture);
        break;
      default:
        price = job.prezzo;
        break;
    }

    return price;
  };


  // Estrai l'array di lavori da eseguire dal JSON
  const jobsArray = checkBoxStep2; //Object.values(checkBoxStep2)

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-3 lg:px-8">
      <div className="sm:mx-auto sm:w-full">
        <h1 className="mt-10 mb-10 text-2xl font-bold text-center">Step 2: Servizi di base</h1>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full">
        <form className="flex md:flex-wrap-reverse">
        <div className="basis-2/3 pr-10 pl-10">
        {jobsArray.map((job, index) => (
          <div key={index} className=" space-y-6">
            <div className="relative flex gap-x-2 mb-5">
              <div className="flex h-4 items-center">
                <input
                  name={`item-${job.id}`}
                  type="checkbox"
                  id={`item-${job.id}`}
                  value={job.nome}
                  onChange={handleCheckboxChange}
                  checked={selectedItems.includes(job.nome)}
                  className="h-4 w-4 rounded border-gray-300 radius text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="text-sm leading-5">
                <label htmlFor={`item-${job.id}`} className="font-blod font-bold uppercase">{job.nome} <small className="text-gray-500">(€ {job.prezzo})</small></label>
                <p key={index} className="text-gray-500">{job.desc}</p>
                <hr />
                {selectedItems.includes(job.nome) && (
                  <p className="text-gray-500">Prezzo calcolato in base a {job.tipo_calcolo == "ML" ? "metri lineari" : job.tipo_calcolo == "MQ" ? "metri quadri" : job.tipo_calcolo == "NR" ? "numero di strutture" : job.tipo_calcolo}: <span className="text-black font-bold">{calculateSinglePrice({...job, id: job.id.toString()})} €</span></p>
                )}
              </div>
            </div>
          </div>

        ))}
        </div>
          <div className="basis-1/4 shadow-lg h-fit	p-10 rounded-lg self-end">
          <article className="text-wrap">
            <h3 className=" text-2xl font-bold">Dettaglio dei Calcoli</h3>
            <ul className="list-disc text-sm lowercase text-gray-400 mb-3 pl-4 mt-3">
         {selectedItems.map((item, index) => {
              const job = checkBoxStep2.find(job => job.nome === item);
              if (job) {
                return (
                <li className="text-gray-500" key={index}>{job.nome} </li>
                );
              } else {
                return null;
              }
            })}
            </ul>
            <div className="mt-3 mb-3 font-bold">
              <p>Totale</p>
              <p className="font-bold text-xl">{calculateTotal().toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</p>
            </div>
          <button
            type="button"
            className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blackHover-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black-600 sm:max-w-sm"
            onClick={handleSubmitStep2}
          >
            Continua
          </button>
          </article>
          </div>
        </form>
      </div>
    </div>
  );
};


const Step3: React.FC<{ onSubmit: (selectedItemsStep3: string[]) => void; formData: any; handleSubmitStep2: any; partialValue: number; setPartial2: (partialTotal2: number) => void;}> = ({ onSubmit, formData, handleSubmitStep2, partialValue, setPartial2 }) => {

  // Definisci lo stato per gli elementi selezionati
  const [selectedItemsStep3, setSelectedItemsStep3] = useState<string[]>([]);
  //totale dello step 3
  const [partialTotal, setPartialTotal] = useState<number>(0);

    const handleCheckboxChange3 = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target;
      if (checked) {
        setSelectedItemsStep3([...selectedItemsStep3, value]);
      } else {
        setSelectedItemsStep3(selectedItemsStep3.filter(item => item !== value));
      }
    };


    const calculateTotal = (): number => {
      let total = 0;

      if (selectedItemsStep3 && formData) {
        selectedItemsStep3.forEach(item => {
          const job3 = checkBoxStep3.find(job3 => job3.nome === item);
          if (job3) {
            let price = job3.prezzo;

            switch (job3.tipo_calcolo) {
              case "ML":
                price *= parseFloat(formData.metriLineari);
                break;
              case "MQ":
                price = job3.prezzo * parseFloat(formData.metriQuadri);
                break;
              case "NR":
                price *= parseFloat(formData.numStrutture);
                break;
              default:
                break;
            }

            total += price;
          }
        });
      }
      return total;
    };


  // Funzione per gestire l'invio del form
  const handleSubmitStep3 = () => {
    // Salva i dati selezionati nel cookie
    Cookies.set('Step3', JSON.stringify(selectedItemsStep3));
    const total = calculateTotal();
    setPartial2(total);
    // Esegue la funzione di callback
    onSubmit(selectedItemsStep3);
  };

  const calculateSinglePriceStep3 = (job3: Job3): number => {
    let price = 0;
    const mqValue = parseFloat(formData.metriQuadri);
    switch (job3.tipo_calcolo) {
      case 'ML':
        price = job3.prezzo * parseFloat(formData.metriLineari);
        break;
      case 'MQ':
          price = job3.prezzo * parseFloat(formData.metriQuadri);
        break;
      case 'NR':
        price = job3.prezzo * parseFloat(formData.numStrutture);
        break;
      default:
        price = job3.prezzo;
        break;
    }

    return price;
  };


  // Estrai l'array di lavori da eseguire dal JSON
  const jobsArray3 = checkBoxStep3; //Object.values(checkBoxStep3)

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-3 lg:px-8">
      <div className="sm:mx-auto sm:w-full">
        <h1 className="mt-10 mb-10 text-2xl font-bold text-center">Step 3: Servizi aggiuntivi</h1>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full">
        <form className="flex md:flex-wrap-reverse">
        <div className="basis-2/3 pr-10 pl-10">
        {jobsArray3.map((job3, index) => (
          <div key={index} className=" space-y-6">
            <div className="relative flex gap-x-2 mb-5">
              <div className="flex h-4 items-center">
                <input
                  name={`item-${job3.id}`}
                  type="checkbox"
                  id={`item-${job3.id}`}
                  value={job3.nome}
                  onChange={handleCheckboxChange3}
                  checked={selectedItemsStep3.includes(job3.nome)}
                  className="h-4 w-4 rounded border-gray-300 radius text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="text-sm leading-5">
                <label htmlFor={`item-${job3.id}`} className="font-blod font-bold uppercase">{job3.nome} <small className="text-gray-500">(€ {job3.prezzo})</small></label>
                <p key={index} className="text-gray-500">{job3.desc}</p>
                <hr />
                {selectedItemsStep3.includes(job3.nome) && (
                  <p className="text-gray-500">Prezzo calcolato in base a {job3.tipo_calcolo == "ML" ? "metri lineari" : job3.tipo_calcolo == "MQ" ? "metri quadri" : job3.tipo_calcolo == "NR" ? "numero di strutture" : job3.tipo_calcolo}: <span className="text-black font-bold">{calculateSinglePriceStep3({...job3, id: job3.id.toString()})} €</span></p>
                )}
              </div>
            </div>
          </div>

        ))}
        </div>
        <div className="basis-1/4 ">
          <div className="shadow-lg h-fit	p-10 rounded-lg self-end mb-2">
            <article className="text-wrap">
            <h3 className=" text-xl font-bold">Resoconto</h3>
            <div className="text-sm lowercase text-gray-400 mb-3 pl-4 mt-3">
              <i>STEP2:</i>
              <ul className="list-disc text-sm lowercase text-gray-500 pl-4">{handleSubmitStep2.map((_ : any, i: number) => {
                return (<li key={i}>{handleSubmitStep2[i]}</li>);
              })}</ul>
              <p className="text-sm lowercase text-gray-500 pl-4">Totale parziale: <span className="font-bold">€{partialValue.toFixed(2)}</span></p>
            </div>
            </article>
          </div>
        <div className="shadow-lg h-fit	p-10 rounded-lg self-end">
          <article className="text-wrap">
            <h3 className=" text-2xl font-bold">Dettaglio dei Calcoli</h3>
            <ul className="list-disc text-sm lowercase text-gray-400 mb-3 pl-4 mt-3">
            {selectedItemsStep3.map((item, index) => {
              const job3 = checkBoxStep3.find(job3 => job3.nome === item);
              if (job3) {
                return (
                <li className="text-gray-500" key={index}>{job3.nome} </li>
                );
              } else {
                return null;
              }
            })}
            </ul>
            <div className="mt-3 mb-3 font-bold">
              <p>Totale</p>
              <p className="font-bold text-xl">{calculateTotal().toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</p>
            </div>
          <button
            type="button"
            className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blackHover-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black-600 sm:max-w-sm"
            onClick={handleSubmitStep3}
          >
            Continua
          </button>
          </article>
          </div>
          </div>
        </form>
      </div>
    </div>
  );
};

//step4 - extra product
const Step4: React.FC<{ onSubmit: (selectedItemsStep4: string[]) => void; formData: any; handleSubmitStep2: any; handleSubmitStep3: any; partialValue: number; partialValue2: number; setPartial3: (partialTotal3: number) => void;}> = ({ onSubmit, formData, handleSubmitStep2, handleSubmitStep3, partialValue, partialValue2, setPartial3 }) => {

  // Definisci lo stato per gli elementi selezionati
  const [selectedItemsStep4, setSelectedItemsStep4] = useState<string[]>([]);
  //totale dello step 4
  const [partialTotal, setPartialTotal] = useState<number>(0);

    const handleCheckboxChange4 = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target;
      if (checked) {
        setSelectedItemsStep4([...selectedItemsStep4, value]);
      } else {
        setSelectedItemsStep4(selectedItemsStep4.filter(item => item !== value));
      }
    };


    const calculateTotal = (): number => {
      let total = 0;

      if (selectedItemsStep4 && formData) {
        selectedItemsStep4.forEach(item => {
          const job4 = checkBoxStep4.find(job4 => job4.nome === item);
          if (job4) {
            let price = job4.prezzo;

            switch (job4.tipo_calcolo) {
              case "ML":
                price *= parseFloat(formData.metriLineari);
                break;
              case "MQ":
                price = job4.prezzo * parseFloat(formData.metriQuadri);
                break;
              case "NR":
                price *= parseFloat(formData.numStrutture);
                break;
              default:
                break;
            }

            total += price;
          }
        });
      }
      return total;
    };


  // Funzione per gestire l'invio del form
  const handleSubmitStep4 = () => {
    // Salva i dati selezionati nel cookie
    Cookies.set('Step4', JSON.stringify(selectedItemsStep4));
    const total = calculateTotal();
    setPartial3(total);
    // Esegue la funzione di callback
    onSubmit(selectedItemsStep4);
  };

  const calculateSinglePriceStep4 = (job4: Job4): number => {
    let price = 0;
    const mqValue = parseFloat(formData.metriQuadri);
    switch (job4.tipo_calcolo) {
      case 'ML':
        price = job4.prezzo * parseFloat(formData.metriLineari);
        break;
      case 'MQ':
          price = job4.prezzo * parseFloat(formData.metriQuadri);
        break;
      case 'NR':
        price = job4.prezzo * parseFloat(formData.numStrutture);
        break;
      default:
        price = job4.prezzo;
        break;
    }

    return price;
  };


  // Estrai l'array di lavori da eseguire dal JSON
  const jobsArray4 = checkBoxStep4; //Object.values(checkBoxStep4)

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-3 lg:px-8">
      <div className="sm:mx-auto sm:w-full">
        <h1 className="mt-10 mb-10 text-2xl font-bold text-center">Step 4: Servizi extra</h1>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full">
        <form className="flex md:flex-wrap-reverse">
        <div className="basis-2/3 pr-10 pl-10">
        {jobsArray4.map((job4, index) => (
          <div key={index} className=" space-y-6">
            <div className="relative flex gap-x-2 mb-5">
              <div className="flex h-4 items-center">
                <input
                  name={`item-${job4.id}`}
                  type="checkbox"
                  id={`item-${job4.id}`}
                  value={job4.nome}
                  onChange={handleCheckboxChange4}
                  checked={selectedItemsStep4.includes(job4.nome)}
                  className="h-4 w-4 rounded border-gray-300 radius text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="text-sm leading-5">
                <label htmlFor={`item-${job4.id}`} className="font-blod font-bold uppercase">{job4.nome} <small className="text-gray-500">(€ {job4.prezzo})</small></label>
                <p key={index} className="text-gray-500">{job4.desc}</p>
                <hr />
                {selectedItemsStep4.includes(job4.nome) && (
                  <p className="text-gray-500">Prezzo calcolato in base a {job4.tipo_calcolo == "ML" ? "metri lineari" : job4.tipo_calcolo == "MQ" ? "metri quadri" : job4.tipo_calcolo == "NR" ? "numero di strutture" : job4.tipo_calcolo}: <span className="text-black font-bold">{calculateSinglePriceStep4({...job4, id: job4.id.toString()})} €</span></p>
                )}
              </div>
            </div>
          </div>

        ))}
        </div>
        <div className="basis-1/4 ">
          <div className="shadow-lg h-fit	p-10 rounded-lg self-end mb-2">
            <article className="text-wrap">
            <h3 className=" text-xl font-bold">Resoconto</h3>
            <div className="text-sm lowercase text-gray-400 mb-3 pl-4 mt-3">
              <i>STEP2:</i>
              <ul className="list-disc text-sm lowercase text-gray-500 pl-4">{handleSubmitStep2.map((_ : any, i: number) => {
                return (<li key={i}>{handleSubmitStep2[i]}</li>);
              })}</ul>
              <p className="text-sm lowercase text-gray-500 pl-4">Totale parziale: <span className="font-bold">€{partialValue.toFixed(2)}</span></p>
            </div>
            </article>
          </div>
          <div className="shadow-lg h-fit	p-10 rounded-lg self-end mb-2">
            <article className="text-wrap">
            <h3 className=" text-xl font-bold">Resoconto</h3>
            <div className="text-sm lowercase text-gray-400 mb-3 pl-4 mt-3">
              <i>STEP3:</i>
              <ul className="list-disc text-sm lowercase text-gray-500 pl-4">{handleSubmitStep3.map((_ : any, i: number) => {
                return (<li key={i}>{handleSubmitStep3[i]}</li>);
              })}</ul>
              <p className="text-sm lowercase text-gray-500 pl-4">Totale parziale: <span className="font-bold">€{partialValue2.toFixed(2)}</span></p>
            </div>
            </article>
          </div>
        <div className="shadow-lg h-fit	p-10 rounded-lg self-end">
          <article className="text-wrap">
            <h3 className=" text-2xl font-bold">Dettaglio dei Calcoli</h3>
            <ul className="list-disc text-sm lowercase text-gray-400 mb-3 pl-4 mt-3">
            {selectedItemsStep4.map((item, index) => {
              const job4 = checkBoxStep3.find(job4 => job4.nome === item);
              if (job4) {
                return (
                <li className="text-gray-500" key={index}>{job4.nome} </li>
                );
              } else {
                return null;
              }
            })}
            </ul>
            <div className="mt-3 mb-3 font-bold">
              <p>Totale</p>
              <p className="font-bold text-xl">{calculateTotal().toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</p>
            </div>
          <button
            type="button"
            className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blackHover-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black-600 sm:max-w-sm"
            onClick={handleSubmitStep4}
          >
            Continua
          </button>
          </article>
          </div>
          </div>
        </form>
      </div>
    </div>
  );
};



const FinalStep: React.FC<{ onSubmit: (checkout: string[]) => void; formData: any; handleSubmitStep2: any; handleSubmitStep3: any; handleSubmitStep4: any; partialValue: number; partialValue2: number; partialValue3: number }> = ({ onSubmit, formData, handleSubmitStep2, handleSubmitStep3, handleSubmitStep4, partialValue, partialValue2, partialValue3 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };



let finalTotal = parseFloat(partialValue.toFixed(2)) + parseFloat(partialValue2.toFixed(2)) + parseFloat(partialValue3.toFixed(2));

const navigate = useNavigate();

const handlePrintPdfClick = () => {

  navigate('/print-pdf');
};

return (
<div className="container m-auto mt-10">

  <div className="">
    <div className="shadow-lg h-fit	p-10 rounded-lg self-end mb-2">
      <article className="text-wrap text-center">
      <h3 className=" text-2xl font-bold pb-5">Dati del Cliente e della struttura</h3>
      <div className="text-sm lowercase text-gray-400 mb-3 pl-4 mt-3">

      <div className="max-w-4xl grid grid-cols-3 gap-1 m-auto pt-5 pb-5">

      <div className="text-md font-bold capitalize text-black-500 pl-4 center w-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 center m-auto">
        <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
      </svg>
      <p className="pt-3">{formData.nome} {formData.cognome}</p>
      </div>

      <div className="text-md font-bold capitalize text-black-500 pl-4 center w-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 center m-auto">
        <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
        <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
      </svg>
      <p className="pt-3 lowercase">{formData.email}</p>
      </div>

      <div className="text-md font-bold capitalize text-black-500 pl-4 center w-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 center m-auto">
        <path d="M10.5 18.75a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" />
        <path fillRule="evenodd" d="M8.625.75A3.375 3.375 0 0 0 5.25 4.125v15.75a3.375 3.375 0 0 0 3.375 3.375h6.75a3.375 3.375 0 0 0 3.375-3.375V4.125A3.375 3.375 0 0 0 15.375.75h-6.75ZM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 0 1 7.5 19.875V4.125Z" clipRule="evenodd" />
      </svg>

      <p className="pt-3">{formData.telefono}</p>
      </div>

      </div>

      <div className="max-w-4xl grid grid-cols-3 gap-1 m-auto pt-10 pb-5">

      <div className="text-md font-bold capitalize text-black-500 pl-4 center w-full">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 center m-auto">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
      </svg>
      <p className="pt-3">{formData.metriQuadri} mq</p>
      </div>

      <div className="text-md font-bold capitalize text-black-500 pl-4 center w-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 center m-auto">
        <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
        <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
      </svg>
      <p className="pt-3">{formData.metriLineari} m.l.</p>
      </div>

      <div className="text-md font-bold capitalize text-black-500 pl-4 center w-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 center m-auto">
        <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
        <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
      </svg>
      <p className="pt-3">{formData.numStrutture} {formData.numStrutture == 1 ? "Struttura" : "Strutture"}</p>
      </div>

      </div>


      </div>
      </article>
    </div>
  </div>

  <div className="">
    <div className="shadow-lg h-fit	p-10 rounded-lg self-end mb-2">
    <div className="max-w-4xl grid grid-cols-2 gap-4 m-auto pt-10 pb-5">
      <div>
        <h1 className="pt-3 text-2xl font-bold">Preventivo finale</h1>
        <h2 className=" text-4xl font-bold">{finalTotal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</h2>
      </div>
      <div>
      <button type="submit" onClick={openModal} className="flex w-full bg-black justify-center rounded-md bg-indigo-600 px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blackHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-5 sm:max-w-sm"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-5 h-5 leading-5 pt-1">
  <path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
  <path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
</svg>
 Invia il preventivo</button>
 <SendLoadPdf formData={formData} handleSubmitStep2={handleSubmitStep2} handleSubmitStep3={handleSubmitStep3} handleSubmitStep4={handleSubmitStep4} partialValue={partialValue} partialValue2={partialValue2} partialValue3={partialValue3} />
 <Transition.Root show={isOpen} as={React.Fragment}>
       <Dialog as="div" onClose={closeModal}>
         <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
           <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
         </Transition.Child>

         <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
           <div className="fixed z-10 inset-0 overflow-y-auto">
             <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
               <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                 <div className="absolute inset-0 bg-gray-500 opacity-75" />
               </div>

               <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

               <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                 <div style={{background: "white"}} className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                   <PopupForm onClose={closeModal} formData={formData} handleSubmitStep2={handleSubmitStep2} handleSubmitStep3={handleSubmitStep3} handleSubmitStep4={handleSubmitStep4} partialValue={partialValue} partialValue2={partialValue2} partialValue3={partialValue3} />
                 </div>
               </Transition.Child>
             </div>
           </div>
         </Transition.Child>
       </Dialog>
     </Transition.Root>
      </div>
    </div>
    </div>
  </div>
<div className="flex m-auto flex-row gap-6">
<div className="basis-1/3 pt-5">
<div className="shadow-lg h-fit	p-10 rounded-lg self-end mb-2">
  <article className="text-wrap">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 mb-3">
    <path d="M19.006 3.705a.75.75 0 1 0-.512-1.41L6 6.838V3a.75.75 0 0 0-.75-.75h-1.5A.75.75 0 0 0 3 3v4.93l-1.006.365a.75.75 0 0 0 .512 1.41l16.5-6Z" />
    <path fillRule="evenodd" d="M3.019 11.114 18 5.667v3.421l4.006 1.457a.75.75 0 1 1-.512 1.41l-.494-.18v8.475h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3v-9.129l.019-.007ZM18 20.25v-9.566l1.5.546v9.02H18Zm-9-6a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75V15a.75.75 0 0 0-.75-.75H9Z" clipRule="evenodd" />
  </svg>

      <h3 className=" text-xl font-bold">Resoconto Servizi Base</h3>
      <div className="text-sm lowercase text-gray-400 mb-3 mt-3">
        <i>STEP2:</i>
        <ul className="list-disc text-sm lowercase text-gray-500">{handleSubmitStep2.map((_ : any, i: number) => {
          return (<li key={i}>{handleSubmitStep2[i]}</li>);
        })}</ul>
          <p className="text-xl capitalize text-green-100 mt-3"> <span className="font-bold">{partialValue.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</span></p>
      </div>
      </article>
    </div>
  </div>

    <div className="basis-1/3 pt-5">
    <div className="shadow-lg h-fit	p-10 rounded-lg self-end mb-2">
      <article className="text-wrap">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 mb-3">
        <path fillRule="evenodd" d="M12 6.75a5.25 5.25 0 0 1 6.775-5.025.75.75 0 0 1 .313 1.248l-3.32 3.319c.063.475.276.934.641 1.299.365.365.824.578 1.3.64l3.318-3.319a.75.75 0 0 1 1.248.313 5.25 5.25 0 0 1-5.472 6.756c-1.018-.086-1.87.1-2.309.634L7.344 21.3A3.298 3.298 0 1 1 2.7 16.657l8.684-7.151c.533-.44.72-1.291.634-2.309A5.342 5.342 0 0 1 12 6.75ZM4.117 19.125a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Z" clipRule="evenodd" />
        <path d="m10.076 8.64-2.201-2.2V4.874a.75.75 0 0 0-.364-.643l-3.75-2.25a.75.75 0 0 0-.916.113l-.75.75a.75.75 0 0 0-.113.916l2.25 3.75a.75.75 0 0 0 .643.364h1.564l2.062 2.062 1.575-1.297Z" />
        <path fillRule="evenodd" d="m12.556 17.329 4.183 4.182a3.375 3.375 0 0 0 4.773-4.773l-3.306-3.305a6.803 6.803 0 0 1-1.53.043c-.394-.034-.682-.006-.867.042a.589.589 0 0 0-.167.063l-3.086 3.748Zm3.414-1.36a.75.75 0 0 1 1.06 0l1.875 1.876a.75.75 0 1 1-1.06 1.06L15.97 17.03a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
      </svg>

      <h3 className=" text-xl font-bold">Resoconto Servizi Aggiuntivi</h3>
      <div className="text-sm lowercase text-gray-400 mb-3 mt-3">
        <i>STEP3:</i>
        <ul className="list-disc text-sm lowercase text-gray-500 pl-4">{handleSubmitStep3.map((_ : any, i: number) => {
          return (<li key={i}>{handleSubmitStep3[i]}</li>);
        })}</ul>
        <p className="text-xl capitalize text-green-100 mt-3"><span className="font-bold">{partialValue2.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</span></p>
      </div>
      </article>
    </div>
    </div>

    <div className="basis-1/3">
    <div className="shadow-lg h-fit	p-10 rounded-lg self-end mb-2">
      <article className="text-wrap">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 mb-3">
        <path fillRule="evenodd" d="M2.25 4.125c0-1.036.84-1.875 1.875-1.875h5.25c1.036 0 1.875.84 1.875 1.875V17.25a4.5 4.5 0 1 1-9 0V4.125Zm4.5 14.25a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" clipRule="evenodd" />
        <path d="M10.719 21.75h9.156c1.036 0 1.875-.84 1.875-1.875v-5.25c0-1.036-.84-1.875-1.875-1.875h-.14l-8.742 8.743c-.09.089-.18.175-.274.257ZM12.738 17.625l6.474-6.474a1.875 1.875 0 0 0 0-2.651L15.5 4.787a1.875 1.875 0 0 0-2.651 0l-.1.099V17.25c0 .126-.003.251-.01.375Z" />
      </svg>
      <h3 className=" text-xl font-bold">Resoconto Servizi Extra</h3>
      <div className="text-sm lowercase text-gray-400 mb-3 mt-3">
        <i>STEP4:</i>
        <ul className="list-disc text-sm lowercase text-gray-500 pl-4">{handleSubmitStep4.map((_ : any, i: number) => {
          return (<li key={i}>{handleSubmitStep4[i]}</li>);
        })}</ul>
      <p className="text-xl capitalize text-green-100 mt-3"><span className="font-bold">{partialValue3.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</span></p>
      </div>
      </article>
    </div>
    </div>
</div>
    </div>
);
}


const MyForm: React.FC = () => {
  const history = useNavigate();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>(null);
  const [step, setStep] = useState(1);
  const [selectedServicesStep2, setSelectedServicesStep2] = useState<string[]>([]); //step2
  const [selectedServicesStep3, setSelectedServicesStep3] = useState<string[]>([]);//step3
  const [selectedServicesStep4, setSelectedServicesStep4] = useState<string[]>([]);//step4
  const [partialValue, setPartialValue] = useState<number>(0);//step2
  const [partialValue2, setPartialValue2] = useState<number>(0);//step3
  const [partialValue3, setPartialValue3] = useState<number>(0);//step4
  const [checkout, setCheckout] = useState<any>(null);//stepfinale

  const checkBoxStep2: Job[] = []; // Assicurati di inizializzare checkBoxStep2 con i dati corretti
  const checkBoxStep3: Job3[] = [];
  const checkBoxStep4: Job4[] = [];
  const [checkRender, setCheckRender] = useState<any>(null); //vedi se è loggato per fare il return degli step


  useEffect(() => {
      // Controlla se il cookie è impostato a "ok"
      var userCheck = Cookies.get('user');
      var sessionCheck = sessionStorage.getItem('user');
      setCheckRender(userCheck);
      if (sessionCheck) {
          sessionCheck = sessionCheck.toString();
      } else {
        navigate('/login');
      }

      if (!userCheck || !sessionCheck || Base64.atob(Base64.atob(Base64.atob(sessionCheck))).substring(Base64.atob(Base64.atob(Base64.atob(sessionCheck))).length - 2) !== '12') {
        // Se il cookie non è impostato correttamente, reindirizza alla pagina di login
        navigate('/login');
      }
    }, [navigate]);

  const handleNextStep = (data: any) => {
     setFormData(data);
     setStep(step + 1);
   };


  const handleSubmitStep2 = (services: string[]) => {
    setStep(step + 1);
    setSelectedServicesStep2(services);
  };
  const handleTotal = (total: number) => { //step2
    setPartialValue(total);
  };


  const handleSubmitStep3 = (services: string[]) => {
    setStep(step + 1);
    setSelectedServicesStep3(services);
  };
  const handleTotal2 = (total: number) => { //step3
    setPartialValue2(total);
  };


  const handleSubmitStep4 = (services: string[]) => { //step4
    setStep(step + 1);
    setSelectedServicesStep4(services);
  };
  const handleTotal3 = (total: number) => { //step4
    setPartialValue3(total);
  };


  return (
    <div className="flex">
      <div className="w-full">
        {checkRender && step === 1 && <Step1 onNextStep={handleNextStep} />}
        {checkRender && step === 2 && formData && <Step2 formData={formData} onSubmit={handleSubmitStep2} setPartial={handleTotal} />}
        {checkRender && step === 3 && formData && <Step3 formData={formData} partialValue={partialValue} setPartial2={handleTotal2} handleSubmitStep2={selectedServicesStep2} onSubmit={handleSubmitStep3} />}
        {checkRender && step === 4 && formData && <Step4 formData={formData} partialValue={partialValue} partialValue2={partialValue2} handleSubmitStep2={selectedServicesStep2} handleSubmitStep3={selectedServicesStep3} setPartial3={handleTotal3} onSubmit={handleSubmitStep4} />}
        {checkRender && step === 5 && formData && <FinalStep formData={formData} partialValue={partialValue} partialValue2={partialValue2} handleSubmitStep2={selectedServicesStep2} handleSubmitStep3={selectedServicesStep3} handleSubmitStep4={selectedServicesStep4} partialValue3={partialValue3} onSubmit={checkout} />}
      </div>
      <div className="">
      </div>
    </div>
  );
};


export default MyForm;
