import React from 'react';

interface SendLoadPdfProps {
  formData: any;
  handleSubmitStep2: any;
  handleSubmitStep3: any;
  handleSubmitStep4: any;
  partialValue: number;
  partialValue2: number;
  partialValue3: number;
}

const SendLoadPdf: React.FC<SendLoadPdfProps> = ({
  formData,
  handleSubmitStep2,
  handleSubmitStep3,
  handleSubmitStep4,
  partialValue,
  partialValue2,
  partialValue3
}) => {
  const handlePrintPdfClick = () => {
    const requestData = {
      formData: formData,
      handleSubmitStep2: handleSubmitStep2,
      handleSubmitStep3: handleSubmitStep3,
      handleSubmitStep4: handleSubmitStep4,
      partialValue: partialValue,
      partialValue2: partialValue2,
      partialValue3: partialValue3
    };

    const xhr = new XMLHttpRequest();
    const url = 'https://test5.redweblab.it/api/createPdf.php'; // URL dell'API PHP
    const method = 'POST';

    xhr.open(method, url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          const pdfUrl = xhr.responseText;
          window.open(pdfUrl, '_blank');
        } else {
          console.error('Errore durante la creazione del PDF:', xhr.statusText);
        }
      }
    };

    xhr.send(JSON.stringify(requestData));
  };

  return (
    <button
      onClick={handlePrintPdfClick}
      type="button"
      className="flex w-full bg-green justify-center rounded-md bg-indigo-600 px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:darkgreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600  sm:max-w-sm"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-5 h-5 leading-5 pt-1">
        <path fillRule="evenodd" d="M8.5 1.709a.75.75 0 0 0-1 0 8.963 8.963 0 0 1-4.84 2.217.75.75 0 0 0-.654.72 10.499 10.499 0 0 0 5.647 9.672.75.75 0 0 0 .694-.001 10.499 10.499 0 0 0 5.647-9.672.75.75 0 0 0-.654-.719A8.963 8.963 0 0 1 8.5 1.71Zm2.34 5.504a.75.75 0 0 0-1.18-.926L7.394 9.17l-1.156-.99a.75.75 0 1 0-.976 1.138l1.75 1.5a.75.75 0 0 0 1.078-.106l2.75-3.5Z" clipRule="evenodd" />
      </svg>
      Scarica pdf
    </button>
  );
};

export default SendLoadPdf;
