import React from 'react';
import { createRoot } from 'react-dom/client'; // Importa createRoot da react-dom/client invece di react-dom
import './index.css';
import './importTail.css';
import App from './App';

// Ottieni l'elemento 'root' dal DOM
const rootElement = document.getElementById('root');

// Verifica se l'elemento 'root' esiste prima di utilizzare createRoot
if (rootElement) {
  // Utilizza createRoot per inizializzare l'applicazione
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error("L'elemento con ID 'root' non è stato trovato nel DOM.");
}
