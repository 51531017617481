// Importa i moduli necessari
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Definisci gli stili
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

// Definisci il componente PDF
const PrintPdf: React.FC = () => {
  // Definisci il componente PDF
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Section #1</Text>
        </View>
        <View style={styles.section}>
          <Text>Section #2</Text>
        </View>
      </Page>
    </Document>
  );

  // Genera il documento PDF come stringa
  const pdfString = ReactDOMServer.renderToStaticMarkup(<MyDocument />);

  // Qui puoi decidere come gestire pdfString, ad esempio salvandolo su disco

  return (
    <div>
      {/* Contenuto del componente PrintPdf */}
    </div>
  );
}

export default PrintPdf;
