import React, { useState } from 'react';
import Header from "../component/Header";
import { useNavigate } from 'react-router-dom';
import Form from "../component/Form";
import CarouselCustomNavigation from "../component/Slider";
import Cookies from 'js-cookie';

var checkStep1 = Cookies.get('Step1');

const ConfiguratorePage: React.FC = () => {

  return (
  <div>
    <Header />
    <div className="w-full m-auto">
      <Form />
    </div>
  </div>
  );
};

export default ConfiguratorePage;
