// Login.tsx
import React, { useState } from 'react';
import usersData from '../data/utenti.json'; // Importa i dati degli utenti
import Cookies from 'js-cookie';
import { Base64 } from 'js-base64';
import { useNavigate } from 'react-router-dom';


interface User {
  id: number;
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();

    // Verifica se le credenziali sono corrette
    const user: User | undefined = usersData.find((user: User) => user.username === username && user.password === password);

    if (user) {
      sessionStorage.setItem('user', Base64.btoa(Base64.btoa(Base64.btoa(user.username+12))));
      var passCrypt = Base64.btoa(user.password+14);
      Cookies.set('user', Base64.btoa(Base64.btoa(Base64.btoa(user.username+1))));
      Cookies.set('bot_ID_X', Base64.btoa(Base64.btoa(passCrypt)));
      // Login riuscito, puoi reindirizzare l'utente ad altre pagine
      console.log('Login riuscito');
      navigate('/');
    } else {
      setErrorMessage('Credenziali non valide');
    }
  };

  return (
    <>
      {/*

      */}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-16 w-auto"
            src="https://nsholding.it/wp-content/uploads/2023/07/Risorsa-1-1-1.png"
            alt="NSHolding"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Accedi all'account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleLogin} method="POST">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="text-sm">
                  <a href="#" className="font-semibold text-blue hover:text-gray">
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>
          {errorMessage && <p>{errorMessage}</p>}
          <p className="mt-10 text-center text-sm text-gray-500">
            Registrati{' '}
            <a href="#" className="font-semibold leading-6 text-blue hover:text-gray-500">
              Hai bisogno di supporto?
            </a>
          </p>
        </div>
      </div>
    </>
  )
}

export default Login;
