import React, { useState } from 'react';

interface PopupFormProps {
  onClose: () => void;
  formData: any;
  handleSubmitStep2: any;
  handleSubmitStep3: any;
  handleSubmitStep4: any;
  partialValue: number;
  partialValue2: number;
  partialValue3: number;
}

const PopupForm: React.FC<PopupFormProps> = ({
  onClose,
  formData,
  handleSubmitStep2,
  handleSubmitStep3,
  handleSubmitStep4,
  partialValue,
  partialValue2,
  partialValue3
}) => {// Definisci lo stato per i dati del modulo
  const [formState, setFormState] = useState(formData);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const xhr = new XMLHttpRequest();
    const url = 'https://test5.redweblab.it/api/send-email.php'; // URL dell'API PHP
    const method = 'POST';

    xhr.open(method, url, true);
    xhr.setRequestHeader('Content-Type', 'application/json'); // Imposta l'intestazione Content-Type

    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          console.log('Risposta dal server:', xhr.responseText);
          // Gestisci la risposta del server
        } else {
          console.error('Errore durante l\'invio dei dati:', xhr.statusText);
          // Gestisci eventuali errori
        }
      }
    };
    // Costruisci l'oggetto con tutte le costanti e i dati del modulo
     const allData = {
       ...formState,
       partialValue,
       partialValue2,
       partialValue3,
       handleSubmitStep2,
       handleSubmitStep3,
       handleSubmitStep4
     };
     xhr.send(JSON.stringify(allData)); // Invia tutti i dati come stringa JSON
  };

  return (
    <div className="popup">
      <div className="popup-inner m-auto text-center">
        <h1 className="pt-6 text-2xl font-bold">Compila con i dati del destinatario</h1>
        <p className="text-gray-500 p-2 text-sm">I dati saranno inviati in duplice copia anche alla mail dell'operatore.</p>
        <form onSubmit={handleSubmit}>
          <div>
            <input placeholder="Nome e Cognome" type="text" name="name" value={formState.name} onChange={handleChange} className="w-5/6 p-3 mb-5 shadow-md mt-5" />
          </div>
          <div>
            <input placeholder="Email" type="email" name="email" value={formState.email} onChange={handleChange} className="w-5/6 p-3 mb-5 shadow-md" />
          </div>
          {/* Campi nascosti per i dati aggiuntivi */}
          <input type="hidden" name="partialValue" value={formState.partialValue} />
          <input type="hidden" name="partialValue2" value={formState.partialValue2} />
          <input type="hidden" name="partialValue3" value={formState.partialValue3} />
          <button type="submit" className="w-5/6 p-3 mt-5 mb-5 bg-black text-white text-center m-auto">Invia</button>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;
